<template>
  <div class="group-topics-container">
    <div class="group-topics__inner">
      <router-link
        :to="{
          path: `/topic/edit?groupid=${groupID}`
        }"
        v-slot="{ navigate }"
      >
        <div class="group-topic-created" @click="navigate">+&nbsp;创建小组主题</div>
      </router-link>
      <div class="group-topic__list-container">
        <topic-list
          :header-nav="topicHeaderNav"
          :src="topicListRequestUrl"
          :role="groupRole"
          extend="group"
        ></topic-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import topicList from '../topic/_list.vue';

export default {
  name: 'views-group-topics',
  props: ['groupRole', 'groupID'],
  components: {
    topicList,
  },
  data() {
    return {
      topicHeaderNav: [
        {
          name: '排行',
        },
        {
          name: '主题',
        },
        {
          name: '我回应/分享',
        },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    topicListRequestUrl() {
      return `/api/group/${this.groupID}/topic/list`;
    },
  },
};
</script>

<style></style>
